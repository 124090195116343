.At6iEq_icon {
  width: 4rem;
}

@media only screen and (width <= 600px) {
  .At6iEq_icon {
    display: none;
  }
}

.lFfrAq_typography {
  align-items: center;
  gap: 10px;
  line-height: 1;
  display: inline-flex;
}

.lFfrAq_typography.lFfrAq_h1 {
  margin: 0;
  font-family: Dancing Script, cursive;
}

@media only screen and (width <= 600px) {
  .lFfrAq_typography.lFfrAq_h1 {
    font-size: 3.5rem;
  }
}

@media only screen and (width >= 601px) {
  .lFfrAq_typography.lFfrAq_h1 {
    font-size: 4rem;
  }
}

.lFfrAq_typography.lFfrAq_h2 {
  margin: 0;
  font-size: 3rem;
  font-weight: lighter;
}

.lFfrAq_typography.lFfrAq_h3 {
  justify-content: space-between;
  align-items: start;
  margin: 0;
  font-size: 2rem;
  display: flex;
}

.vTYOaW_card {
  background: #24292e;
  border-radius: 1rem;
  flex-direction: column;
  gap: 10px;
  transition: box-shadow .15s ease-in-out;
  display: flex;
  box-shadow: 4px 4px 5px #24292ebf;
}

.vTYOaW_card:hover {
  box-shadow: 4px 4px 15px #24292ebf;
}

.vTYOaW_card > * {
  padding-left: 1rem;
  padding-right: 1rem;
}

.vTYOaW_card > :first-child {
  padding-top: 1rem;
}

.vTYOaW_card > :last-child {
  padding-bottom: 1rem;
}

.bhJLhG_actions {
  gap: 10px;
  display: flex;
}

.tbi6Cq_container {
  flex: 1;
  padding: 0 1rem;
}

.tbi6Cq_image {
  background-position-y: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1rem;
}

.kqIh8W_container {
  display: flex;
  position: relative;
}

.kqIh8W_container:after {
  content: "";
  pointer-events: none;
  width: 10px;
  height: 100%;
  position: absolute;
  right: 100%;
}

.kqIh8W_container .kqIh8W_tooltip {
  color: #24292e;
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  background-color: #fff;
  border-radius: 1rem;
  align-items: center;
  height: 100%;
  margin-right: 10px;
  padding: 0 10px;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1;
  transition: opacity .15s ease-in-out, transform .15s ease-in-out;
  display: flex;
  position: absolute;
  right: 100%;
  transform: translateX(10px);
  box-shadow: 4px 4px 5px #24292ebf;
}

.kqIh8W_container.kqIh8W_success > .kqIh8W_tooltip {
  background-color: #27ae60;
}

.kqIh8W_container.kqIh8W_warning > .kqIh8W_tooltip {
  background-color: #f1c40f;
}

.kqIh8W_container.kqIh8W_disabled > .kqIh8W_tooltip {
  background-color: #7c7f82;
}

.kqIh8W_container:hover:after {
  pointer-events: all;
}

.kqIh8W_container:hover .kqIh8W_tooltip {
  pointer-events: all;
  opacity: 1;
  transform: translateX(0);
}

.eVGbiW_success {
  color: #27ae60;
}

.eVGbiW_warning {
  color: #f1c40f;
}

.eVGbiW_disabled {
  color: #7c7f82;
}

._8YgOtW_link {
  color: #24292e;
  background-color: #fff;
  border-radius: 50%;
  padding: 5px;
  transition: color .15s ease-in-out;
  display: inline-flex;
  position: relative;
}

._8YgOtW_link:before {
  content: "";
  border-radius: inherit;
  pointer-events: none;
  opacity: 0;
  background-color: #f19cbbcc;
  transition: transform .15s ease-in-out, opacity .15s ease-in-out;
  position: absolute;
  inset: 0;
  transform: scale(.5);
}

._8YgOtW_link:hover {
  color: #fff;
}

._8YgOtW_link:hover:before {
  opacity: 1;
  transform: scale(1);
}

._8YgOtW_link ._8YgOtW_icon {
  z-index: 1;
}

.V27tDW_github {
  color: #24292e;
}

.V27tDW_gitlab {
  color: #e2432a;
}

.V27tDW_bitbucket {
  color: #205081;
}

._7MPHiq_card ._7MPHiq_name {
  flex-direction: column;
  flex: 1;
  gap: 10px;
  display: flex;
}

@media only screen and (width >= 600px) {
  ._7MPHiq_card._7MPHiq_library {
    min-width: 20rem;
  }
}

@media only screen and (width <= 600px) {
  ._7MPHiq_card._7MPHiq_library {
    min-width: 100%;
  }
}

._7MPHiq_card._7MPHiq_library ._7MPHiq_description {
  box-sizing: border-box;
  flex: 1;
  max-width: 320px;
}

._7MPHiq_card._7MPHiq_app, ._7MPHiq_card._7MPHiq_framework {
  flex: 1;
  max-width: 750px;
}

@media only screen and (width >= 601px) {
  ._7MPHiq_card._7MPHiq_app, ._7MPHiq_card._7MPHiq_framework {
    min-width: 32rem;
  }
}

@media only screen and (width <= 600px) {
  ._7MPHiq_card._7MPHiq_app, ._7MPHiq_card._7MPHiq_framework {
    min-width: 100%;
  }
}

._20FAWW_badges {
  gap: .5rem;
  display: flex;
}

.Mygs8q_github {
  color: #24292e;
}

.Mygs8q_gitlab {
  color: #e2432a;
}

.Mygs8q_bitbucket {
  color: #205081;
}

._1yzDOa_app {
  flex-direction: column;
  gap: 1rem;
  max-width: 1400px;
  display: flex;
}

._1yzDOa_apps {
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  display: grid;
}

@media only screen and (width <= 1200px) {
  ._1yzDOa_apps {
    grid-template-columns: 1fr;
  }
}

._1yzDOa_libraries {
  flex-wrap: wrap;
  gap: 2rem;
  display: flex;
}

/*# sourceMappingURL=index.19b1b092.css.map */
